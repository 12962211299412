import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "resources-backup-process"
    }}>{`Resources backup process`}</h1>
    <p>{`This document describes what kind of operations are performed by AutoBackup in order to make backups of different types of resources.
We hope that it will help to understand how to configure backup setups correctly and realize limitations imposed by cloud providers.`}</p>
    <h1 {...{
      "id": "aws"
    }}>{`AWS`}</h1>
    <p>{`This section provides a description of backup operations performed on supported AWS and AWS China resources types.`}</p>
    <h2 {...{
      "id": "ec2-instances"
    }}>{`EC2 instances`}</h2>
    <p>{`In order to make backup and replication of an EC2 instance, the following operations are performed by AutoBackup:`}</p>
    <ul>
      <li parentName="ul">{`snapshots of EBS volumes attached to the EC2 instance are created in the protected account,`}</li>
      <li parentName="ul">{`if replication is enabled, snapshots are copied to the vault account into a region specified by a backup setup assigned to the backed up EC2 instance,`}</li>
      <li parentName="ul">{`if sharing snapshot back is enabled, snapshots replicas are shared with protected account.`}</li>
    </ul>
    <h3 {...{
      "id": "sharing-ec2-instance-volumes-snapshots-replicas-back"
    }}>{`Sharing EC2 instance volumes snapshots replicas back`}</h3>
    <p>{`AutoBackup allows sharing EC2 instance's EBS volumes snapshots replicas with the protected account to make restoring
from replicas easier in protected account. Sharing snapshots back with the protected account is not performed by default.
To enable this functionality you must explicitly enable `}<em parentName="p">{`Share snapshot back`}</em>{` option in a backup setup assigned to an EC2 instance.
It is important to note that the shared snapshots will be visible in a protected account in a region where replicas are stored.
For example, if you have an EC2 instance in eu-west-1 region, and you replicate its EBS snapshots to the eu-central-1 region,
then the shared back EBS replicas will be visible in the protected account in eu-central-1 region.
To restore a shared snapshot in the protected account, first, you must copy it from the vault account to the protected account.
If a shared snapshot which you want to copy or restore is encrypted, make sure that the policy of the KMS key used for encryption
grants access for using that key for data decryption by the protected account.`}</p>
    <h3 {...{
      "id": "encryption"
    }}>{`Encryption`}</h3>
    <p>{`If an EBS volume attached to an EC2 instance was encrypted, then snapshots taken in protected account will be automatically
encrypted by the same KMS key as the original volume.
While copying to the vault account, encryption can be forced by enabling `}<em parentName="p">{`Force encryption of unencrypted resources replicas`}</em>{`
setting in a backup setup associated with the backed up EC2 instance.
The snapshot replica will be encrypted by KMS key identified by alias specified in `}<em parentName="p">{`Replicas encryption KMS key alias`}</em>{` backup setup setting.
Note that the KMS key with the specified alias must be present in a replication region and its policy must allow AutoBackup role to use it.`}</p>
    <p>{`Please also note that it's not possible to copy to another account a snapshot which was encrypted using KMS key managed by AWS.
In such case, an additional, temporary copy of snapshot must be created in protected account and encrypted using a
key specified by `}<em parentName="p">{`Default KMS key replacement`}</em>{` backup setup setting.
For EC2 the key specified by `}<em parentName="p">{`Default KMS key replacement`}</em>{` backup setup setting must be present in protected account, in the same region as backed up EC2 instance.`}</p>
    <p>{`If an EBS volume in protected account is not encrypted, then a snapshot taken in protected account also won't be encrypted.
Unencrypted snapshots' replicas can still be encrypted with KMS key while copying to the vault account,
depending on the `}<em parentName="p">{`Force encryption of unencrypted resources replicas`}</em>{` setting.`}</p>
    <p>{`Creating and setting KMS key policies is not part of AutoBackup system. They should be created by a user.`}</p>
    <h2 {...{
      "id": "ebs-volumes"
    }}>{`EBS volumes`}</h2>
    <p>{`In order to make backup and replication of an EBS volume, the following operations are performed by AutoBackup:`}</p>
    <ul>
      <li parentName="ul">{`snapshot of backed up EBS volume is created in the protected account,`}</li>
      <li parentName="ul">{`if replication is enabled, the snapshot is copied to the vault account into a region specified by a backup setup the backed up EBS volume,`}</li>
      <li parentName="ul">{`if sharing snapshot back is enabled, snapshot's replica is shared with protected account.`}</li>
    </ul>
    <h3 {...{
      "id": "sharing-ebs-volumes-snapshots-replicas-back"
    }}>{`Sharing EBS volumes snapshots replicas back`}</h3>
    <p>{`AutoBackup allows sharing EBS volume snapshot's replica with the protected account to make restoring
from replica easier in protected account. Sharing snapshot back with the protected account is not performed by default.
To enable this functionality you must explicitly enable `}<em parentName="p">{`Share snapshot back`}</em>{` option in a backup setup assigned to an EBS volume.
It is important to note that the shared snapshot will be visible in a protected account in a region where replicas are stored.
For example, if you have an EBS volume in eu-west-1 region, and you replicate its snapshots to the eu-central-1 region,
then the shared back EBS replicas will be visible in the protected account in eu-central-1 region.
To restore a shared snapshot in the protected account, first, you must copy it from the vault account to the protected account.
If a shared snapshot which you want to copy or restore is encrypted, make sure that the policy of the KMS key used for encryption
grants access for using that key for data decryption by the protected account.`}</p>
    <h3 {...{
      "id": "encryption-1"
    }}>{`Encryption`}</h3>
    <p>{`If a backed up EBS volume was encrypted, then snapshots taken in protected account will be automatically
encrypted by the same KMS key as the original volume.
While copying to the vault account, encryption can be forced by enabling `}<em parentName="p">{`Force encryption of unencrypted resources replicas`}</em>{`
setting in a backup setup associated with the backed up EBS volume.
The snapshot replica will be encrypted by KMS key identified by alias specified in `}<em parentName="p">{`Replicas encryption KMS key alias`}</em>{` backup setup setting.
Note that the KMS key with the specified alias must be present in a replication region and its policy must allow AutoBackup role to use it.`}</p>
    <p>{`Please also note that it's not possible to copy to another account a snapshot which was encrypted using KMS key managed by AWS.
In such case, an additional, temporary copy of snapshot must be created in protected account and encrypted using a
key specified by `}<em parentName="p">{`Default KMS key replacement`}</em>{` backup setup setting.
For EBS the key specified by `}<em parentName="p">{`Default KMS key replacement`}</em>{` backup setup setting must be present in protected account, in replication region.`}</p>
    <p>{`If an EBS volume in protected account is not encrypted, then a snapshot taken in protected account also won't be encrypted.
Unencrypted snapshots' replicas can still be encrypted with KMS key while copying to the vault account,
depending on the `}<em parentName="p">{`Force encryption of unencrypted resources replicas`}</em>{` setting.`}</p>
    <p>{`Creating and setting KMS key policies is not part of AutoBackup system. They should be created by a user.`}</p>
    <h2 {...{
      "id": "rds-instances"
    }}>{`RDS instances`}</h2>
    <p>{`In order to make backup and replication of an RDS instance, the following operations are performed by AutoBackup:`}</p>
    <ul>
      <li parentName="ul">{`snapshot of backed up RDS instance is created in the protected account,`}</li>
      <li parentName="ul">{`if replication is enabled, the snapshot is copied to the vault account into a region specified by a backup setup the backed up RDS instance,`}</li>
      <li parentName="ul">{`if sharing snapshot back is enabled, snapshot's replica is shared with protected account.`}</li>
    </ul>
    <h3 {...{
      "id": "sharing-rds-instances-snapshots-replicas-back"
    }}>{`Sharing RDS instances snapshots replicas back`}</h3>
    <p>{`AutoBackup allows sharing RDS instance snapshot's replica with the protected account to make restoring
from replica easier in protected account. Sharing snapshot back with the protected account is not performed by default.
To enable this functionality you must explicitly enable `}<em parentName="p">{`Share snapshot back`}</em>{` option in a backup setup assigned to an RDS instance.
It is important to note that the shared snapshot will be visible in a protected account in a region where replicas are stored.
For example, if you have an RDS instance in eu-west-1 region, and you replicate its snapshots to the eu-central-1 region,
then the shared back RDS instance replicas will be visible in the protected account in eu-central-1 region.
To restore a shared snapshot in the protected account, first, you must copy it from the vault account to the protected account.
If a shared snapshot which you want to copy or restore is encrypted, make sure that the policy of the KMS key used for encryption
grants access for using that key for data decryption by the protected account.`}</p>
    <h3 {...{
      "id": "encryption-2"
    }}>{`Encryption`}</h3>
    <p>{`If a backed up RDS instance was encrypted, then snapshots taken in protected account will be automatically
encrypted by the same KMS key as the original instance.
While copying to the vault account, encryption can be forced by enabling `}<em parentName="p">{`Force encryption of unencrypted resources replicas`}</em>{`
setting in a backup setup associated with the backed up RDS instance.
The snapshot replica will be encrypted by KMS key identified by alias specified in `}<em parentName="p">{`Replicas encryption KMS key alias`}</em>{` backup setup setting.
Note that the KMS key with the specified alias must be present in a replication region and its policy must allow AutoBackup role to use it.`}</p>
    <p>{`Please also note that it's not possible to copy to another account a snapshot which was encrypted using KMS key managed by AWS.
In such case, an additional, temporary copy of snapshot must be created in protected account and encrypted using a
key specified by `}<em parentName="p">{`Default KMS key replacement`}</em>{` backup setup setting.
For RDS instances the key specified by `}<em parentName="p">{`Default KMS key replacement`}</em>{` backup setup setting must be present in protected account, in replication region.`}</p>
    <p>{`If an RDS instance in protected account is not encrypted, then a snapshot taken in protected account also won't be encrypted.
Unencrypted snapshots' replicas can still be encrypted with KMS key while copying to the vault account,
depending on the `}<em parentName="p">{`Force encryption of unencrypted resources replicas`}</em>{` setting.`}</p>
    <p>{`Creating and setting KMS key policies is not part of AutoBackup system. They should be created by a user.`}</p>
    <h2 {...{
      "id": "rds-clusters"
    }}>{`RDS clusters`}</h2>
    <p>{`In order to make backup and replication of an RDS cluster, the following operations are performed by AutoBackup:`}</p>
    <ul>
      <li parentName="ul">{`manual snapshot of RDS cluster is created in the protected account,`}</li>
      <li parentName="ul">{`if replication is enabled, snapshot is copied to the vault account into a region specified by a backup setup assigned to the backed up RDS cluster,`}</li>
      <li parentName="ul">{`if sharing snapshot back is enabled, snapshot's replica is shared with protected account.`}</li>
    </ul>
    <h3 {...{
      "id": "sharing-rds-clusters-snapshot-back"
    }}>{`Sharing RDS cluster's snapshot back`}</h3>
    <p>{`AutoBackup allows sharing RDS cluster's snapshots replicas with the protected account to make restoring
from replicas easier in protected account. Sharing snapshots back with the protected account is not performed by default.
To enable this functionality you must explicitly enable `}<em parentName="p">{`Share snapshot back`}</em>{` option in a backup setup assigned to an RDS cluster.
It is important to note that the shared snapshots will be visible in a protected account in a region where replicas are stored.
For example, if you have an RDS cluster in eu-west-1 region, and you replicate its snapshot to the eu-central-1 region,
then the shared back RDS replicas will be visible in the protected account in eu-central-1 region.
To restore a shared snapshot in the protected account, first, you must copy it from the vault account to the protected account.
If a shared snapshot which you want to copy or restore is encrypted, make sure that the policy of the KMS key used for encryption
grants access for using that key for data decryption by the protected account.`}</p>
    <h3 {...{
      "id": "encryption-3"
    }}>{`Encryption`}</h3>
    <p>{`If an RDS cluster was encrypted, then snapshots taken in protected account will be automatically
encrypted by the same KMS key as the original database.
The snapshot replica will be encrypted by KMS key identified by alias specified in `}<em parentName="p">{`Replicas encryption KMS key alias`}</em>{` backup setup setting.
Note that the KMS key with the specified alias must be present in a replication region and its policy must allow AutoBackup role to use it.`}</p>
    <p>{`Please also note that it's not possible to copy to another account a snapshot which was encrypted using KMS key managed by AWS.
In such case, an additional, temporary copy of snapshot must be created in protected account and encrypted using a
key specified by `}<em parentName="p">{`Default KMS key replacement`}</em>{` backup setup setting.
For RDS clusters the key specified by `}<em parentName="p">{`Default KMS key replacement`}</em>{` backup setup setting must be present in protected account, in replication region.`}</p>
    <p>{`If an RDS cluster in protected account is not encrypted, then a snapshot taken in protected account also won't be encrypted.
Unlike RDS instances and EBS volumes snapshots, unencrypted RDS clusters snapshots replicas can't be encrypted
with KMS key while copying to the vault account. It means that RDS clusters snapshots replicas are encrypted if and only
if backed up RDS cluster is encrypted.`}</p>
    <p>{`Creating and setting KMS key policies is not part of AutoBackup system. They should be created by a user.`}</p>
    <h2 {...{
      "id": "dynamodb-tables"
    }}>{`DynamoDB tables`}</h2>
    <p>{`In order to make backup and replication of a DynamoDB table, the following operations are performed by AutoBackup:`}</p>
    <ul>
      <li parentName="ul">{`table snapshot is created in the protected account using AWS DynamoDB On-Demand Backup feature,`}</li>
      <li parentName="ul">{`if replication is enabled, a temporary DynamoDB table is created from snapshot, then data from the temporary table is copied to S3 bucket in a vault account.`}</li>
    </ul>
    <h3 {...{
      "id": "replication-performance-tuning"
    }}>{`Replication performance tuning`}</h3>
    <p>{`DynamoDB Backup is using `}<em parentName="p">{`Replicas storing bucket name`}</em>{` backup setup setting, to point an S3 bucket in vault account
which will store table data in a vault account. Data from temporary DynamoDB table is copied to that bucket.
Speed of replication can be tuned using `}<em parentName="p">{`Temporary DynamoDB table read capacity units`}</em>{` backup setup setting.
By default, when no specific number of read capacity units is provided in a backup setup, AutoBackup creates a temporary
table with on-demand mode.`}</p>
    <h2 {...{
      "id": "s3-buckets"
    }}>{`S3 buckets`}</h2>
    <p>{`In order to make backup of S3 buckets, AutoBackup uses native AWS cross-region/same-region replication to replicate objects.
The AutoBackup doesn't make any snapshots in protected account.
This implies that all objects present in bucket prior to enabling S3 Backup will `}<strong parentName="p">{`NOT BE REPLICATED`}</strong>{`.`}</p>
    <p>{`It is important to note, that in order for S3 Backup to work properly, backup setup setting named `}<em parentName="p">{`Replication enabled`}</em>{` must be enabled.`}</p>
    <p>{`After enabling S3 Backup, all changes to the bucket objects will be tracked and applied to the bucket in the vault account.
Nothing will happen with objects present in a bucket before enabling S3.
Only after enabling S3 Backup, adding new objects, modifying or deleting existing objects in original bucket,
changes will be replicated to the bucket in the vault account, denoted by `}<em parentName="p">{`Replicas storing bucket name`}</em>{` backup setup setting.`}</p>
    <h3 {...{
      "id": "limiting-replication-scope"
    }}>{`Limiting replication scope`}</h3>
    <p>{`By default, all objects stored in an S3 bucket are replicated. If this is not a desired behaviour, you can change
it using `}<em parentName="p">{`Replicated prefixes`}</em>{` backup setup setting to limit the replication scope only to objects with specified prefixes.`}</p>
    <h3 {...{
      "id": "replicated-objects-ownership"
    }}>{`Replicated objects ownership`}</h3>
    <p>{`By default, all objects replicas are owned by an AWS account in which original bucket resides. In order to change this
behaviour you can enable `}<em parentName="p">{`Override replica owner`}</em>{` backup setup setting. When this setting is enabled, objects replicas ownership
will be overridden and transferred to vault account.`}</p>
    <h3 {...{
      "id": "encryption-4"
    }}>{`Encryption`}</h3>
    <p>{`As stated in AWS documentation, by default, Amazon S3 doesn't replicate objects that are stored at rest using server-side
encryption with AWS Key Management Service. In order to replicate encrypted objects, specify `}<em parentName="p">{`Replicas encryption KMS key alias`}</em>{`
backup setup setting and provide an alias of KMS key which will be used to encrypt replicas.`}</p>
    <h3 {...{
      "id": "s3-backup-events-semantics"
    }}>{`S3 backup events semantics`}</h3>
    <p>{`For S3 buckets, backup events have a special semantics. They define, when the Auto-Backup will check for changes of S3 bucket backup setup,
describing desired replication settings.
The retention settings have no impact on backup of S3 buckets, because during backup of those resources,
no snapshots are created, hence there are no snapshots to remove.`}</p>
    <h2 {...{
      "id": "redshift"
    }}>{`Redshift`}</h2>
    <p>{`Redshift backup is done using AWS Redshift native manual snapshots' mechanism.`}</p>
    <h3 {...{
      "id": "configuring-redshift-backup"
    }}>{`Configuring Redshift Backup`}</h3>
    <p>{`Redshift Backup does not require any special configuration, you just need to enable backup for it.
Snapshots will be taken accordingly to meet RPO conditions defined by the backup policy.`}</p>
    <h3 {...{
      "id": "known-limitations"
    }}>{`Known limitations`}</h3>
    <ul>
      <li parentName="ul">{`replication of snapshots is not supported,`}</li>
      <li parentName="ul">{`there is a default AWS limit of 20 manual snapshots per region in an account.`}</li>
    </ul>
    <h2 {...{
      "id": "efs"
    }}>{`EFS`}</h2>
    <p>{`AutoBackup uses native AWS backup vaults for backup of EFS filesystems.`}</p>
    <h3 {...{
      "id": "backup-using-native-aws-backup-vaults"
    }}>{`Backup using native AWS backup vaults`}</h3>
    <p>{`EFS snapshots are stored in the AWS backup vault in the protected account.
The specific vault has to be chosen and specified in the `}<em parentName="p">{`Backup vault name`}</em>{` setting of a backup setup assigned to an EFS resource.
The snapshots are then created in the protected account inside the specified AWS backup vault. They are not replicated
to vault account.`}</p>
    <h3 {...{
      "id": "replication-to-s3"
    }}>{`Replication to S3`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Method deprecated`}</strong>{` - will be removed soon`}</p>
    </blockquote>
    <p>{`AutoBackup can replicate EFS filesystem's contents to S3 bucket in vault account. In order to have replication to S3
working you must set the following backup setup settings:`}</p>
    <ul>
      <li parentName="ul">{`Replicas storing bucket name`}</li>
      <li parentName="ul">{`Subnet ID`}</li>
      <li parentName="ul">{`Security group ID`}</li>
      <li parentName="ul">{`ARN of an instance profile`}</li>
      <li parentName="ul">{`Vault account id
In order to perform replication, AutoBackup launches an EC2 instance using settings listed above, mounts the filesystem
and the EC2 instance copies filesystem's contents to specified S3 bucket.`}</li>
    </ul>
    <h3 {...{
      "id": "replication-to-aws-backup-vault"
    }}>{`Replication to AWS Backup Vault`}</h3>
    <blockquote>
      <p parentName="blockquote">{`For currently onboarded accounts please update role definition (could be downloaded from the UI - Accounts page)`}</p>
    </blockquote>
    <p>{`AutoBackup can replicate EFS primary snapshot to vault account. To perform this action Cross-account backup (in AWS Backup Settings) must be enabled and
replica AWS Backup vault should have access policy that allows to copy snapshot from protected account to the vault.
For more details please visit `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/aws-backup/latest/devguide/recov-point-create-a-copy.html"
      }}>{`AWS documentation`}</a></p>
    <p>{`Required permissions for PROTECTED account:`}</p>
    <ul>
      <li parentName="ul">{`backup:StartCopyJob`}</li>
      <li parentName="ul">{`backup:CopyIntoBackupVault`}</li>
      <li parentName="ul">{`backup:CopyFromBackupVault`}</li>
    </ul>
    <p>{`Required permission for VAULT account:`}</p>
    <ul>
      <li parentName="ul">{`backup:DeleteRecoveryPoint`}</li>
    </ul>
    <p>{`Access policy example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "Allow PROTECTED account to copy into vault",
            "Effect": "Allow",
            "Principal": {
                "AWS": "arn:aws:iam::<protected account id>:root"
            },
            "Action": "backup:CopyIntoBackupVault",
            "Resource": "*"
        }
    ]
}
`}</code></pre>
    <h2 {...{
      "id": "route53"
    }}>{`Route53`}</h2>
    <p>{`Route 53 backup is performed by making snapshots of the following components' configuration:`}</p>
    <ul>
      <li parentName="ul">{`hosted zones,`}</li>
      <li parentName="ul">{`health checks,`}</li>
      <li parentName="ul">{`traffic policies and traffic policy instances.`}</li>
    </ul>
    <p>{`The snapshots are stored in a special S3 bucket in the protected account. AutoBackup searches for a bucket with a name
specified in `}<em parentName="p">{`Storing bucket name`}</em>{` backup setup setting.
Snapshots can be replicated to S3 bucket in the vault account. You can specify a bucket for storing replicas by
providing a value for `}<em parentName="p">{`Replicas storing bucket name`}</em>{` backup setup setting.`}</p>
    <h3 {...{
      "id": "encryption-5"
    }}>{`Encryption`}</h3>
    <p>{`Snapshots replicas can optionally be encrypted. In order to make encryption enabled, enable
`}<em parentName="p">{`Force encryption of unencrypted resources replicas`}</em>{` backup setup setting and provide a value for
the `}<em parentName="p">{`Replicas encryption KMS key ARN or alias`}</em>{` backup setup setting to specify an alias of KMS key which should be used to encrypt replicas.
Make sure that KMS key is present in the same region as a bucket designated to store replicas.
The KMS key policy must allow the key usage by AutoBackup role.`}</p>
    <h1 {...{
      "id": "azure"
    }}>{`Azure`}</h1>
    <p>{`This section provides a description of backup operations performed on supported Azure resources types.`}</p>
    <h2 {...{
      "id": "azure-vm"
    }}>{`Azure VM`}</h2>
    <p>{`AutoBackup uses native Azure backup vaults mechanism in order to perform Azure VM backup. AutoBackup automatically
creates required backup vault, sets up a policy and adds VM to the vault. When backup event occurs, AutoBackup uses manual
backup job trigger in order to take snapshot.`}</p>
    <p>{`Snapshots replication is fully managed by Azure. AutoBackup sets geo-redundant replication type for utilized backup vaults.`}</p>
    <p>{`AutoBackup also does not control the retention process. Azure removes obsolete snapshots automatically.`}</p>
    <p>{`Azure allows to take at most 1 snapshot per 12 hours. Users should be aware of it when they will be creating backup policies for Azure VMs.`}</p>
    <h2 {...{
      "id": "azure-sap-hana"
    }}>{`Azure SAP HANA`}</h2>
    <p><strong parentName="p">{`Before you start`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`all the `}<a parentName="li" {...{
          "href": "https://docs.microsoft.com/en-us/azure/backup/tutorial-backup-sap-hana-db#prerequisites"
        }}>{`prerequisites`}</a>{` must be met,`}</li>
      <li parentName="ul">{`a `}<a parentName="li" {...{
          "href": "https://aka.ms/scriptforpermsonhana"
        }}>{`pre-registration script`}</a>{` must be run on the SAP HANA machine.
More information about what the script does, refer to the `}<a parentName="li" {...{
          "href": "https://docs.microsoft.com/en-us/azure/backup/tutorial-backup-sap-hana-db#what-the-pre-registration-script-does"
        }}>{`page`}</a>{`,`}</li>
      <li parentName="ul">{`to allow AutoBackup to distinguish normal Virtual Machine from the SAP HANA instance, make sure that this instance is tagged with following tag:
`}<strong parentName="li">{`<nc-backup-resource-type: sap-hana>`}</strong></li>
    </ul>
    <p>{`AutoBackup uses native Azure backup vaults mechanism in order to perform Azure SAP HANA backup. AutoBackup automatically
creates required backup vault, sets up a policy, refreshes container, registers container and adds databases installed on the VM to the vault.
When backup event occurs, AutoBackup uses manual backup job trigger in order to take snapshot.`}</p>
    <p>{`Snapshots replication is fully managed by Azure. AutoBackup sets geo-redundant replication type for utilized backup vaults.`}</p>
    <p>{`AutoBackup also does not control the retention process. Azure removes obsolete snapshots automatically.`}</p>
    <h3 {...{
      "id": "known-limitations-1"
    }}>{`Known limitations`}</h3>
    <p>{`For Azure SAP HANA retention will not be kept according to the attached policy (AutoBackup triggers snapshots manually to keep the RPO but the default
retention for on-demand backup is 45 days - `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/backup/backup-azure-sap-hana-database#run-an-on-demand-backup"
      }}>{`Azure documentation`}</a>{`)`}</p>
    <h1 {...{
      "id": "gcp"
    }}>{`GCP`}</h1>
    <p>{`This section provides a description of backup operations performed on supported GCP resources types.`}</p>
    <h2 {...{
      "id": "gcp-vm"
    }}>{`GCP VM`}</h2>
    <p>{`Primary backup is performed by saving metadata about a virtual machine and taking snapshots of all disks attached to the virtual machine. Identifiers of created snapshots are saved alongside with instance metadata.`}</p>
    <p>{`GCP does not have a native mechanism for copying snapshots. In order to perform replication, AutoBackup must create disks images based on primary snapshots, share the images with vault account, create temporary disks in vault account and take snapshots of those temporary disks.`}</p>
    <p>{`The user can specify the location of created snapshots. For both primary and replica snapshots, snapshots can be stored in a single region or in a multi-region. To configure Regional snapshots (Multi-region is default), use the appropriate options: `}<inlineCode parentName="p">{`Use Regional location for primary backup/replication`}</inlineCode>{`.`}</p>
    <h1 {...{
      "id": "ibm-cloud"
    }}>{`IBM Cloud`}</h1>
    <p>{`This section provides a description of backup operations performed on supported IBM Cloud resources types.`}</p>
    <h2 {...{
      "id": "ibm-cloud-vm"
    }}>{`IBM Cloud VM`}</h2>
    <p>{`Required permissions:`}</p>
    <ul>
      <li parentName="ul">{`is.instance.instance.read`}</li>
      <li parentName="ul">{`is.instance.instance.operate`}</li>
      <li parentName="ul">{`is.volume.volume.read`}</li>
      <li parentName="ul">{`is.volume.volume.operate`}</li>
      <li parentName="ul">{`is.snapshot.snapshot.read`}</li>
      <li parentName="ul">{`is.snapshot.snapshot.create`}</li>
      <li parentName="ul">{`is.snapshot.snapshot.delete`}</li>
      <li parentName="ul">{`global-search-tagging.tag.attach-user-tag`}</li>
      <li parentName="ul">{`global-search-tagging.resource.read`}</li>
      <li parentName="ul">{`resource-controller.instance.create`}</li>
    </ul>
    <p>{`Primary backup is performed by saving metadata about a virtual machine and attached volumes, and taking snapshots of all volumes (boot and data volumes) attached to the virtual machine.
Tags are propagated from volumes to theirs snapshots as user tags. Information about created snapshots are saved alongside with obtained metadata.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      